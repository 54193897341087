














































import { Component, Prop, Watch } from 'vue-property-decorator';
import VueBaseWidget from '@/utils/widgets/VueBaseWidget';
import { mixins } from 'vue-class-component';
import VueRegisterStoreWidget from '@/utils/widgets/VueRegisterStoreWidget';
import { State } from 'vuex-class';
import DateTimeHelper from '@utils/helpers/DateTimeHelper';

@Component({
  inheritAttrs: false,
})
export default class CountdownWidget extends mixins(VueBaseWidget, VueRegisterStoreWidget) {
  baseStoreName = 'CountdownWidgetStore';

  @Prop({ required: false, default: null })
  private readonly label!: string | null;

  @Prop({ required: false, default: null })
  private readonly date!: string | null;

  @State
  private selectedTzName!: string;

  private days: number | null = null;

  private hours: number | null = null;

  private minutes: number | null = null;

  private seconds: number | null = null;

  get showCountdown(): boolean {
    return this.days !== null && this.hours !== null && this.minutes !== null && this.seconds !== null;
  }

  get getDate(): Date | null {
    if (this.date) {
      return DateTimeHelper.utcToZonedTimeDate(
        `${this.date}Z`,
        this.selectedTzName,
      );
    }
    return null;
  }

  created(): void {
    this.setDataConfig();
    this.setCountdown();
  }

  @Watch('date')
  setCountdown(): void {
    const date = this.getDate;
    if (date) {
      const second = 1000;
      const minute = second * 60;
      const hour = minute * 60;
      const day = hour * 24;
      const countDown = date.getTime();

      const calcData = (): void => {
        const current = DateTimeHelper.utcToZonedTimeDate(
          (new Date()).toISOString(),
          this.selectedTzName,
        );
        const distance = countDown - current.getTime();
        if (distance > 0) {
          this.days = Math.floor(distance / (day));
          this.hours = Math.floor((distance % (day)) / (hour));
          this.minutes = Math.floor((distance % (hour)) / (minute));
          this.seconds = Math.floor((distance % (minute)) / second);
        } else {
          this.days = 0;
          this.hours = 0;
          this.minutes = 0;
          this.seconds = 0;
        }
      };

      calcData();
      const timer = setInterval(() => {
        calcData();

        const distance = countDown - new Date().getTime();
        if (distance <= 0) {
          clearInterval(timer);
        }
      }, 1000);
    }
  }
}
